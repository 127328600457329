@use "styles/colours";
@use "styles/spacing";
@use "sass:color";

.grid__menu-item {
    all: unset; // clear any default browser styling
    display: block;
    padding: 10px;
    cursor: pointer;
    transition: all 250ms ease-out;
    border-radius: 4px;

    &:not(.grid__menu-item--is-active):hover {
        background-color: colours.$background-secondary;
    }

    &--is-active {
        background-color: color.adjust(colours.$primary, $lightness: 40%);
    }
    &--disabled {
        cursor: not-allowed;
        opacity: 0.5;
    }

    & + & {
        margin-top: spacing.$spacing-xxx-small;
    }
}
