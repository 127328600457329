@use "@edgetier/styles/sass/theme";
@use "@edgetier/styles/sass/spacing";
@use "@edgetier/styles/sass/variables";
@use "@edgetier/styles/sass/colours";
@use "sass:color";

$color: #444;

.markdown {
    overflow-y: auto;
    white-space: pre-wrap;
    overflow-wrap: break-word;
    word-wrap: break-word;
    -ms-word-break: break-all;
    word-break: break-word;

    > p:first-of-type {
        margin-top: 0;
    }

    ul,
    ol {
        display: block;
        list-style: disc outside none;
        margin: spacing.$spacing-base 0;
        padding: 0 0 0 spacing.$spacing-xx-large;
    }

    ol {
        list-style-type: decimal;
    }

    li {
        display: list-item;
    }

    ul ul,
    ol ul {
        list-style-type: circle;
        margin-left: spacing.$spacing-base;
    }

    ol ol,
    ul ol {
        list-style-type: lower-latin;
        margin-left: spacing.$spacing-base;
    }

    hr {
        height: 1px;
        border: 0;
        @include theme.theme {
            border-bottom: 2px solid theme.get(divider);
        }
    }

    a {
        $link-colour: colours.$flat-peter-river;
        color: $link-colour;
        text-decoration: underline;
        transition: color 250ms;
        &:hover {
            color: color.adjust($link-colour, $lightness: -20%);
        }
    }

    pre,
    code {
        font-family: Monaco, Consolas, monospace;
        font-size: variables.$font-size-base;
        @include theme.theme {
            background-color: theme.get(background-tertiary);
        }
    }

    blockquote {
        @include theme.theme {
            background-color: theme.get(background-secondary);
            border-left: 3px solid theme.get(divider);
        }
    }

    pre {
        line-height: variables.$line-height-base;
        white-space: pre-wrap;
    }

    pre,
    blockquote {
        padding: spacing.$spacing-base;
        margin: spacing.$spacing-base 0;
        p {
            margin: 0;
        }
    }

    img {
        display: block;
        margin: spacing.$spacing-base auto;
        max-height: 500px;
        width: auto;
        max-width: 100%;
    }

    em {
        font-style: italic;
    }

    &__writing-issue-tooltip {
        max-width: 400px;
        word-wrap: break-word;
    }

    .writing-issue {
        color: colours.$flat-midnight-blue;
        background-position: -1px calc(100% + 3px);
        border-bottom: 1px solid transparent;
        background-repeat: repeat-x;

        &--spelling {
            $highlight-background: color.adjust(colours.$flat-pomegranate, $lightness: 35%);
            background-image: url("data:image/svg+xml;base64,PHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHdpZHRoPSI0IiBoZWlnaHQ9IjUiPgogIDxkZWZzPgogICAgICA8cGF0dGVybiB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIGlkPSJzcGVsbGluZy16aWd6YWciIHdpZHRoPSI0IiBoZWlnaHQ9IjUiIHBhdHRlcm5Vbml0cz0ib2JqZWN0Qm91bmRpbmdCb3giIHBhdHRlcm5Db250ZW50VW5pdHM9InVzZXJTcGFjZU9uVXNlIiB4PSIwIiB5PSIwIj4KICAgICAgICA8cGF0aCBkPSJNIDAgMyBMIDIgMSBMIDQgMyIgc3Ryb2tlPSIjRUE0MzM1IiBzdHJva2Utd2lkdGg9IjEiIGZpbGw9Im5vbmUiIHN0cm9rZS1saW5lY2FwPSJzcXVhcmUiLz4KICAgICAgPC9wYXR0ZXJuPgogICAgPC9kZWZzPgogIDxyZWN0IHhtbG5zPSJodHRwOi8vd3d3LnczLm9yZy8yMDAwL3N2ZyIgZmlsbD0idXJsKCNzcGVsbGluZy16aWd6YWcpIiBoZWlnaHQ9IjEwMCUiIHdpZHRoPSIxMDAlIiAvPgo8L3N2Zz4=");
            background-color: $highlight-background;
        }

        &--grammar {
            $highlight-background: color.adjust(colours.$flat-peter-river, $lightness: 30%);
            background-image: url("data:image/svg+xml;base64,PHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHdpZHRoPSI0IiBoZWlnaHQ9IjUiPgogIDxkZWZzPgogICAgICA8cGF0dGVybiB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIGlkPSJzcGVsbGluZy16aWd6YWciIHdpZHRoPSI0IiBoZWlnaHQ9IjUiIHBhdHRlcm5Vbml0cz0ib2JqZWN0Qm91bmRpbmdCb3giIHBhdHRlcm5Db250ZW50VW5pdHM9InVzZXJTcGFjZU9uVXNlIiB4PSIwIiB5PSIwIj4KICAgICAgICA8cGF0aCBkPSJNIDAgMyBMIDIgMSBMIDQgMyIgc3Ryb2tlPSIjNDI4NUY0IiBzdHJva2Utd2lkdGg9IjEiIGZpbGw9Im5vbmUiIHN0cm9rZS1saW5lY2FwPSJzcXVhcmUiLz4KICAgICAgPC9wYXR0ZXJuPgogICAgPC9kZWZzPgogIDxyZWN0IHhtbG5zPSJodHRwOi8vd3d3LnczLm9yZy8yMDAwL3N2ZyIgZmlsbD0idXJsKCNzcGVsbGluZy16aWd6YWcpIiBoZWlnaHQ9IjEwMCUiIHdpZHRoPSIxMDAlIiAvPgo8L3N2Zz4=");
            background-color: $highlight-background;
        }
    }
}
