/*
  Mixin for applying ellipsis to any element
*/
/*
  Mixin for applying gradient slide text effect
  @param $base-color: Base color for the gradient (default: colours.$flat-midnight-blue)
  @param $color-1: First color in the gradient (default: colours.$flat-emerald)
  @param $color-2: Second color in the gradient (default: colours.$flat-peter-river)
  @param $animation-duration: Duration of the animation (default: 3s)
*/
.grid__pagination-numbers {
  height: 40px;
  width: 60px;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 1.1rem;
  margin: 0 1px;
  background-color: white;
}

