/**
  * Library components styles
  */
@use "@edgetier/components/styles" as components-styles;
@use "@edgetier/client-components/styles" as client-components-styles;
@use "@edgetier/dynamic-form/styles" as dynamic-form-styles;

// No dependencies
@use "styles/pages";
@use "styles/reset";
@use "styles/tables";

// Local dependencies
/**
  * Import message styles as a partial because the chat screen and the search screen need these styles but they don't
  * share a component for displaying chat messages so, because of code splitting, they won't have these shared styles.
  * If you try put the styles in shared/chat-messages/chat-messsage it will affect the chat window.
    @use "styles/theme";
    @use "styles/colours";
    @use "styles/spacing";
    @use "styles/breakpoints";
    @use "styles/variables";
  */
@use "styles/chat-messages";
/**
    * Import message styles as a partial because the chat screen and the search screen need these styles but they don't
        @use "spacing";
        @use "variables";
    */
@use "styles/filters";
/**
      * Import message styles as a partial because the chat screen and the search screen need these styles but they don't
        @use "spacing";
        @use "colours";
        @use "theme";
        @use "variables";
        @use "sass:math";
    */
@use "styles/forms";
/**
      * Import message styles as a partial because the chat screen and the search screen need these styles but they don't
        @use "theme";
    */
@use "styles/loading";
/**
      * Import message styles as a partial because the chat screen and the search screen need these styles but they don't
        @use "variables";
        @use "spacing";
        @use "theme";
    */
@use "styles/modal";
/**
      * Import message styles as a partial because the chat screen and the search screen need these styles but they don't
        @use "root-variables" as *;
        @use "spacing";
        @use "theme";
        @use "colours";
    */
@use "styles/navigation";
/**
      * Import message styles as a partial because the chat screen and the search screen need these styles but they don't
        @use "colours";
        @use "theme";
        @use "spacing";
        @use "variables";
    */
@use "styles/reporting";
/**
      * Import message styles as a partial because the chat screen and the search screen need these styles but they don't
        @use "variables";
        @use "styles/breakpoints";
    */
@use "styles/root-variables";
/**
      * Import message styles as a partial because the chat screen and the search screen need these styles but they don't
        @use "styles/theme";
    */
@use "styles/scroll";
/**
      * Import message styles as a partial because the chat screen and the search screen need these styles but they don't
        @use "spacing";
        @use "theme";
        @use "variables";
    */
@use "styles/select";
/**
    * Import message styles as a partial because the chat screen and the search screen need these styles but they don't
        @use "variables";
        @use "spacing";
        @use "theme";
    */
@use "styles/toast";
/**
      * Import message styles as a partial because the chat screen and the search screen need these styles but they don't
        @use "spacing";
        @use "theme";
        @use "variables";
    */
@use "styles/type";
/**
      * Import message styles as a partial because the chat screen and the search screen need these styles but they don't
        @use "spacing";
    */
@use "styles/variables";

// Workspace dependencies
/**
    @forward "@edgetier/client-components/dist/styles/breakpoints";
*/
@use "styles/breakpoints";
/**
    @forward "@edgetier/styles/sass/colours";
    @use "@edgetier/styles/sass/colours" as *;
*/
@use "styles/colours";
/**
    @forward "@edgetier/styles/sass/spacing";
    @use "@edgetier/styles/sass/spacing";
*/
@use "styles/spacing";
/**
    @forward "@edgetier/styles/sass/theme";
*/
@use "styles/theme";

/**
  * Node dependencies. Defaults can be overwritten in preceding imports
  */
@use "react-toggle/style";
@use "sass:color";

html {
    box-sizing: border-box;
}

*,
*:before,
*:after {
    box-sizing: inherit;
}

html,
body,
#root {
    height: 100%;
}

body {
    @include theme.theme {
        background-color: theme.get(background-secondary);
    }
}

h1,
h2,
h3 {
    line-height: variables.$heading-line-height;
}

h4 {
    line-height: 35px;
}

::selection {
    background: colours.$flat-green-sea;
    color: white;
}

::-moz-selection {
    background: colours.$flat-green-sea;
    color: white;
}

.tooltip {
    &__content {
        @include theme.theme {
            background-color: theme.get(pop-up-background);
            color: theme.get(pop-up-color);
        }
        .none {
            @include theme.theme {
                color: color.adjust(theme.get(pop-up-color), $lightness: -20%);
            }
        }
    }
}

.toggle-translation__button--inactive {
    cursor: pointer;

    @include theme.theme {
        color: theme.get(label);
    }

    &:hover {
        @include theme.theme {
            color: theme.get(primary);
        }
    }
}

.react-query-devtools__button {
    svg {
        opacity: 0;
    }
    &:hover {
        svg {
            opacity: 100;
        }
    }
}
