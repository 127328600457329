/*
  Mixin for applying ellipsis to any element
*/
/*
  Mixin for applying gradient slide text effect
  @param $base-color: Base color for the gradient (default: colours.$flat-midnight-blue)
  @param $color-1: First color in the gradient (default: colours.$flat-emerald)
  @param $color-2: Second color in the gradient (default: colours.$flat-peter-river)
  @param $animation-duration: Duration of the animation (default: 3s)
*/
.grid__filters {
  display: flex;
  flex: 1;
}
.grid__filters .field-inline {
  width: 280px;
  height: auto;
  min-height: 40px;
}
.grid__filters .field-inline + .field-inline {
  margin-left: 8px;
}

