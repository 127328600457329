/*
  Mixin for applying ellipsis to any element
*/
/*
  Mixin for applying gradient slide text effect
  @param $base-color: Base color for the gradient (default: colours.$flat-midnight-blue)
  @param $color-1: First color in the gradient (default: colours.$flat-emerald)
  @param $color-2: Second color in the gradient (default: colours.$flat-peter-river)
  @param $animation-duration: Duration of the animation (default: 3s)
*/
.grid__heading {
  position: relative;
  line-height: 1.69rem;
}
.grid__heading div {
  display: inline-flex;
  align-items: center;
}
.grid__heading svg {
  transition: color 150ms ease-out;
  margin-left: 4px;
  color: rgba(127, 140, 141, 0.5);
}
.grid__heading:not(.grid__heading--is-filtered):hover > div > svg {
  color: #3498db;
}
.grid__heading--has-menu {
  cursor: pointer;
}
thead th .grid__heading--is-filtered {
  color: #3498db;
}
.grid__heading > div {
  transition: all 250ms ease-out;
  height: 100%;
  align-items: center;
}
.grid__heading--is-filtered > div {
  background-color: rgb(190.8241758242, 246.1758241758, 235.3461538462);
  outline: 8px solid rgb(190.8241758242, 246.1758241758, 235.3461538462);
  color: #16a085;
  border-radius: 1px;
}
.grid__heading--is-filtered > div svg {
  color: #16a085;
}
.grid__heading--is-filtered:hover > div {
  background-color: rgb(145.989010989, 240.010989011, 221.6153846154);
  outline: 8px solid rgb(145.989010989, 240.010989011, 221.6153846154);
}

.grid__menu {
  padding: 10px;
  background-color: white;
  box-shadow: 0 -1px 3px -1px rgba(0, 0, 0, 0.2), 0 2px 1px rgba(0, 0, 0, 0.1), 0 5px 20px rgba(0, 0, 0, 0.3);
  border-radius: 4px;
  max-height: 70vh;
  overflow-y: scroll;
}

