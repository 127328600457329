/*
  Mixin for applying ellipsis to any element
*/
/*
  Mixin for applying gradient slide text effect
  @param $base-color: Base color for the gradient (default: colours.$flat-midnight-blue)
  @param $color-1: First color in the gradient (default: colours.$flat-emerald)
  @param $color-2: Second color in the gradient (default: colours.$flat-peter-river)
  @param $animation-duration: Duration of the animation (default: 3s)
*/
.box-header {
  margin-top: 16px;
  margin-right: 16px;
  margin-left: 16px;
  padding: 16px;
  background-color: rgb(241.8727272727, 244.6363636364, 245.3272727273);
  border-bottom: 1px solid #ecf0f1;
  position: relative;
  border-radius: 6px;
}

