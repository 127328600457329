/*
  Mixin for applying ellipsis to any element
*/
/*
  Mixin for applying gradient slide text effect
  @param $base-color: Base color for the gradient (default: colours.$flat-midnight-blue)
  @param $color-1: First color in the gradient (default: colours.$flat-emerald)
  @param $color-2: Second color in the gradient (default: colours.$flat-peter-river)
  @param $animation-duration: Duration of the animation (default: 3s)
*/
.drop-down-menu {
  position: relative;
  box-shadow: 0 2px 1px -1px rgba(0, 0, 0, 0.04);
  line-height: 38px;
  border-radius: 2px;
  z-index: 1;
  background-color: #ecf0f1;
  border: 1px solid rgb(215.4454545455, 223.7727272727, 225.8545454545);
}
@keyframes drop-down-menu-appear {
  0% {
    opacity: 0;
    transform: scaleY(0) translateY(-10px);
  }
}
@keyframes drop-down-menu-disappear {
  0% {
    opacity: 1;
    transform: scaleY(1) translateY(0);
  }
}
.drop-down-menu div {
  cursor: pointer;
  padding: 0 8px;
  transition: all 300ms ease-out;
  border-top-right-radius: 2px;
  border-top-left-radius: 2px;
}
.drop-down-menu__icon {
  margin-left: 10px;
}
.drop-down-menu:not(.drop-down-menu--open):hover div {
  border-radius: 2px;
  background-color: rgb(215.4454545455, 223.7727272727, 225.8545454545);
}
.drop-down-menu--open ul {
  opacity: 1;
  animation: drop-down-menu-appear 300ms ease;
  transform: scaleY(1) translateY(0);
}
.drop-down-menu:not(.drop-down-menu--open) ul {
  opacity: 0;
  animation: drop-down-menu-disappear 0 ease;
  transform: scaleY(0) translateY(-10px);
}
.drop-down-menu-open div,
.drop-down-menu ul {
  background-color: #34495e;
  color: white;
}
.drop-down-menu ul {
  transform-origin: center top;
  position: absolute;
  top: 100%;
  right: 0;
  box-shadow: 0 2px 10px rgba(0, 0, 0, 0.5);
  text-align: left;
  border-bottom-left-radius: 2px;
  border-bottom-right-radius: 2px;
  border-top-left-radius: 2px;
}
.drop-down-menu ul svg {
  color: #bdc3c7;
  margin-right: 8px;
}
.drop-down-menu li,
.drop-down-menu li svg {
  transition: all 150ms ease-out;
}
.drop-down-menu li {
  line-height: 50px;
  padding: 0 12px;
  white-space: nowrap;
  display: flex;
  align-items: center;
}
.drop-down-menu li:not(.drop-down-menu--disabled) {
  cursor: pointer;
}
.drop-down-menu li:not(.drop-down-menu--disabled):hover {
  background-color: #2c3e50;
}
.drop-down-menu li:not(.drop-down-menu--disabled):hover svg {
  color: #3498db;
}
.drop-down-menu li + li {
  border-top: 1px solid #2c3e50;
}
.drop-down-menu--disabled {
  cursor: not-allowed;
  opacity: 0.25;
}
.drop-down-menu--disabled div {
  cursor: not-allowed;
}

