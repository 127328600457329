@use "styles/colours";
@use "styles/variables";
@use "sass:color";

.button--split {
    button {
        margin-top: 0px !important;
        border-radius: 3px 0px 0px 3px;
    }
    &--no-dropdown-options {
        button {
            border-radius: 3px;
        }
    }
    &__container {
        display: flex;
        align-items: center;
    }
    &--drop-down-only {
        button {
            cursor: unset !important;
        }
        button[disabled] {
            border-top: 1px solid colours.$flat-peter-river;
            border-bottom: 1px solid colours.$flat-peter-river;
            border-left: 1px solid colours.$flat-peter-river;
            color: colours.$flat-peter-river;
            background-color: white;
        }

        .button--positive:disabled {
            border-top: 1px solid colours.$flat-green-sea;
            border-bottom: 1px solid colours.$flat-green-sea;
            border-left: 1px solid colours.$flat-green-sea;
            color: colours.$flat-green-sea;
            background-color: white;
        }
    }

    .drop-down-menu {
        button {
            all: unset;
            text-transform: uppercase;
            font-size: variables.$font-size-small;
        }
        color: white;
        border: none;
        border-left: 1px solid rgba(black, 0.1);
        border-radius: 0px 3px 3px 0px;

        &:not(.button--split-disabled):not([disabled]) {
            background-color: colours.$flat-peter-river;
        }
        &:not(.button--split-disabled):not([disabled]):hover {
            div {
                background-color: colours.$flat-belize-hole;
            }
        }
        div:after {
            margin: 0;
        }
        div {
            height: variables.$button-height;
        }
        li,
        div,
        button {
            line-height: variables.$button-height;
            font-weight: bold;
        }
        ul {
            margin-top: 1px;
        }
        ul,
        li {
            border-radius: 3px;
        }

        &__icon {
            margin-left: 0;
        }
    }

    &--negative {
        .drop-down-menu {
            &:not(.button--split-disabled):not([disabled]) {
                background-color: colours.$flat-alizarin;
            }
            &:not(.button--split-disabled):not([disabled]):hover {
                div {
                    background-color: colours.$flat-pomegranate;
                }
            }
        }
    }

    &--positive {
        .drop-down-menu {
            &:not(.button--split-disabled):not([disabled]) {
                background-color: colours.$flat-green-sea;
            }
            &:not(.button--split-disabled):not([disabled]):hover {
                div {
                    background-color: color.adjust(colours.$flat-green-sea, $lightness: -5%);
                }
            }
        }
    }
}
