@use "styles/colours";
@use "sass:color";

.grid__row {
    &--active {
        outline: 1px solid colours.$positive;

        &,
        td {
            transition: all 150ms ease-out;
        }

        td {
            background-color: rgba(colours.$positive, 0.075);
        }
    }

    &--is-clickable {
        cursor: pointer;
    }

    &--fixed {
        font-weight: bold;
        color: colours.$primary;
    }

    &--fixed + tr:not(.grid__row--fixed) {
        border-top: 1px solid rgba(colours.$primary, 0.3);
    }

    &--warning {
        background-color: color.adjust(colours.$negative, $lightness: 35%);

        &:hover {
            background-color: color.adjust(colours.$negative, $lightness: 32%);
        }
    }

    &--new-activity {
        background-color: color.adjust(colours.$negative, $lightness: 37%);

        &:hover {
            background-color: color.adjust(colours.$negative, $lightness: 33%);
        }
    }
}
.grid tbody tr:not(.grid__empty):not(.grid__row--is-loading).grid__row--highlighted {
    background-color: color.adjust(colours.$primary, $lightness: 44%);

    &:hover {
        background-color: color.adjust(colours.$primary, $lightness: 41%);
    }

    &:last-of-type {
        border-bottom: 2px solid colours.$negative;
    }
}
