/*
  Mixin for applying ellipsis to any element
*/
/*
  Mixin for applying gradient slide text effect
  @param $base-color: Base color for the gradient (default: colours.$flat-midnight-blue)
  @param $color-1: First color in the gradient (default: colours.$flat-emerald)
  @param $color-2: Second color in the gradient (default: colours.$flat-peter-river)
  @param $animation-duration: Duration of the animation (default: 3s)
*/
@keyframes enter {
  0% {
    opacity: 0;
    transform: translateY(-5%);
  }
  100% {
    transform: translateY(0);
  }
}
.client-login {
  position: absolute;
  animation: enter 100ms ease-in;
  top: 25%;
  left: 50%;
  width: 500px;
  margin-left: -250px;
  opacity: 1;
  text-align: center;
  color: #2c3e50;
}
@media (max-width: 479px) {
  .client-login {
    top: 50%;
    transform: translateY(calc(-50% + 13px));
    left: 0;
    right: 0;
    margin: 0 auto;
    width: 90%;
  }
}
.client-login img {
  height: 22px;
}
@media (max-width: 479px) {
  .client-login__title {
    font-size: 16px;
  }
}
.client-login .button {
  display: block;
  width: 100%;
}
.client-login .button + .button {
  margin-top: 8px;
}
.client-login__background {
  height: calc(25% + 50px);
  position: fixed;
  background-position: center;
  background-size: contain;
  background-color: #3498db;
  width: 100%;
  background-repeat: no-repeat;
}
@media (max-width: 479px) {
  .client-login__background {
    height: 50%;
    background-size: 75%;
  }
}
.client-login__container {
  display: flex;
  flex-direction: column;
  gap: 28px;
  padding: 48px;
  background-color: white;
  box-shadow: 0 2px 2px rgba(0, 0, 0, 0.1), 0 -20px 100px rgba(52, 152, 219, 0.1);
  border-radius: 8px;
  border-bottom: 1px solid #bdc3c7;
}
@media (max-width: 479px) {
  .client-login__container {
    padding: 28px;
    gap: 16px;
  }
}
.client-login__version {
  color: #7f8c8d;
  margin-top: 8px;
}

