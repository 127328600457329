@use "styles/colours";
@use "styles/spacing";
@use "styles/variables";

.grid__pagination {
    margin-left: auto;
    padding-left: spacing.$spacing-base;

    &__content {
        display: flex;
        box-shadow: 0 2px 1px -1px rgba(0, 0, 0, 0.075);
    }

    &__button {
        text-align: center;
        transition: color 150ms ease-out;
        font-size: 16px;
        padding: 0 spacing.$spacing-small;
        line-height: variables.$button-height;
        cursor: pointer;
        background-color: colours.$background;

        &:first-of-type {
            border-top-left-radius: variables.$border-radius;
            border-bottom-left-radius: variables.$border-radius;
        }

        &:last-of-type {
            border-top-right-radius: variables.$border-radius;
            border-bottom-right-radius: variables.$border-radius;
        }

        &--disabled {
            cursor: not-allowed;
            color: colours.$disabled;
        }
    }

    &__button + &__button {
        margin-left: 1px;
    }
}
