/*
  Mixin for applying ellipsis to any element
*/
/*
  Mixin for applying gradient slide text effect
  @param $base-color: Base color for the gradient (default: colours.$flat-midnight-blue)
  @param $color-1: First color in the gradient (default: colours.$flat-emerald)
  @param $color-2: Second color in the gradient (default: colours.$flat-peter-river)
  @param $animation-duration: Duration of the animation (default: 3s)
*/
.labeled-field {
  position: relative;
}
.labeled-field.field {
  margin-bottom: 0;
  background-color: transparent;
  box-shadow: none;
}
.theme-dark .labeled-field.field {
  background-color: transparent;
}
.theme-light .labeled-field.field {
  background-color: transparent;
}
.theme-contrast .labeled-field.field {
  background-color: transparent;
}
.labeled-field__label, .field > .labeled-field__label {
  font-size: 1.1rem;
  margin-bottom: 6px;
  padding: 0;
  font-weight: 700;
  color: #7f8c8d;
  text-transform: uppercase;
  white-space: nowrap;
  text-overflow: ellipsis;
  max-width: 100%;
  overflow: hidden;
  padding-right: 16px;
}

