/*
  Mixin for applying ellipsis to any element
*/
/*
  Mixin for applying gradient slide text effect
  @param $base-color: Base color for the gradient (default: colours.$flat-midnight-blue)
  @param $color-1: First color in the gradient (default: colours.$flat-emerald)
  @param $color-2: Second color in the gradient (default: colours.$flat-peter-river)
  @param $animation-duration: Duration of the animation (default: 3s)
*/
.page-content {
  flex: 1;
  padding: 16px;
  overflow-y: auto;
}
.page-content__container {
  margin-left: auto;
  margin-right: auto;
  max-width: 100%;
}
.page-content__container--standard {
  max-width: 1200px;
}
.page-content__container--full-height {
  height: 100%;
}

