/*
  Mixin for applying ellipsis to any element
*/
/*
  Mixin for applying gradient slide text effect
  @param $base-color: Base color for the gradient (default: colours.$flat-midnight-blue)
  @param $color-1: First color in the gradient (default: colours.$flat-emerald)
  @param $color-2: Second color in the gradient (default: colours.$flat-peter-river)
  @param $animation-duration: Duration of the animation (default: 3s)
*/
.box-subheading {
  font-size: 1.1rem;
  margin-top: 6px;
  color: rgb(102.2231404959, 113.8801652893, 114.7768595041);
  font-weight: normal;
}

