/*
  Mixin for applying ellipsis to any element
*/
/*
  Mixin for applying gradient slide text effect
  @param $base-color: Base color for the gradient (default: colours.$flat-midnight-blue)
  @param $color-1: First color in the gradient (default: colours.$flat-emerald)
  @param $color-2: Second color in the gradient (default: colours.$flat-peter-river)
  @param $animation-duration: Duration of the animation (default: 3s)
*/
.box-content--horizontal-padding {
  padding-right: 16px;
  padding-left: 16px;
}
.box-content--vertical-padding {
  padding-top: 16px;
  padding-bottom: 16px;
}

