/*
  Mixin for applying ellipsis to any element
*/
/*
  Mixin for applying gradient slide text effect
  @param $base-color: Base color for the gradient (default: colours.$flat-midnight-blue)
  @param $color-1: First color in the gradient (default: colours.$flat-emerald)
  @param $color-2: Second color in the gradient (default: colours.$flat-peter-river)
  @param $animation-duration: Duration of the animation (default: 3s)
*/
.grid__button-cell {
  width: 40px;
}
.grid__button-cell .grid__loading-cell {
  width: 40px;
}
.grid__button-cell + .grid__button-cell, .grid__button-cell + .grid__expand-button {
  padding-left: 0;
}

td:not(.grid__button-cell) + .grid__button-cell {
  border-left: 1px solid rgb(241.8727272727, 244.6363636364, 245.3272727273);
}

