@use "index" as *;
@use "sass:math";
@use "sass:color";

$input-padding: $spacing-large;
$input-font-size: $font-size-base;
$check-radio-font-size: 20px;

textarea {
    resize: none;
    padding-top: 0;
    padding-bottom: 0;
}

textarea,
input[type="date"],
input[type="time"],
input[type="text"],
input[type="number"],
input[type="email"] {
    font-size: $input-font-size;
    line-height: $input-line-height;
    width: 100%;
    padding-left: $input-padding;
    padding-right: $input-padding;
    border: none;
    background-color: $flat-white;
}

textarea:disabled,
input[type="date"]:disabled,
input[type="time"]:disabled,
input[type="text"]:disabled,
input[type="number"]:disabled,
input[type="email"]:disabled,
input[type="range"]:disabled,
.react-select__control--is-disabled {
    opacity: 0.35;
}

input[type="date"],
input[type="time"],
input[type="email"],
input[type="text"],
input[type="number"] {
    line-height: $input-height;
    height: $input-height;
}

textarea:focus,
input:focus {
    outline: none;
}

// Checkboxes.
$checkbox-icon-unchecked: "\f1db";
$checkbox-icon-checked: "\f058";
$checkbox-disabled-icon-color: #aaa;
$checkbox-disabled-label-color: #888;

@mixin check-radio($type, $icon-off, $icon-on) {
    input[type="#{$type}"] {
        position: absolute;
        left: -9999px;
    }

    input[disabled][type="#{$type}"] + label {
        color: $checkbox-disabled-label-color;

        &:before {
            color: $checkbox-disabled-icon-color;
        }

        &,
        &:before {
            cursor: not-allowed;
        }
    }

    input[type="#{$type}"] + label svg {
        display: inline-block;
        text-rendering: auto;
        -webkit-font-smoothing: antialiased;
        -moz-osx-font-smoothing: grayscale;
        transition: color 0.25s linear;
        text-align: center;
        cursor: pointer;
        padding-right: $spacing-xx-small;
        font-size: $check-radio-font-size;
        vertical-align: middle;
        color: $flat-silver;
    }

    &-no-label input[type="#{$type}"] + label:before {
        padding-right: 0;
        text-align: center;
    }

    input[type="#{$type}"]:checked + label svg {
        color: $flat-green-sea;
    }

    label {
        cursor: pointer;
    }

    input[type="#{$type}"]:focus + label svg {
        color: $flat-wet-asphalt;
    }
}

.checkbox {
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
}

.checkbox {
    @include check-radio("checkbox", $checkbox-icon-unchecked, $checkbox-icon-checked);
}

.checkbox + .checkbox {
    padding-bottom: 2px;
}

.select {
    $icon-width: 20px;
    position: relative;
    display: inline-block;

    select {
        background-color: inherit;
        cursor: pointer;
        padding: 0 ($spacing-x-large + $icon-width) 0 $spacing-x-large;
        display: block;
        height: 100%;
        width: 100%;
        border: none;
        -webkit-appearance: none;
        -moz-appearance: none;
        appearance: none;
        text-indent: 1px;
        text-overflow: "";

        &,
        &:focus {
            outline: none;
        }

        &::-ms-expand {
            display: none;
        }
    }

    &:after {
        position: absolute;
        line-height: 20px;
        top: 50%;
        right: $spacing-base;
        margin-top: -10px;
        font: normal normal normal 14px/1 FontAwesome;
        text-rendering: auto;
        -webkit-font-smoothing: antialiased;
        -moz-osx-font-smoothing: grayscale;
        content: "\f107";
        font-size: 20px;
        pointer-events: none;
    }
}

.email-table {
    width: 100%;

    td {
        white-space: pre-line;
        padding-top: $spacing-small;
        padding-bottom: $spacing-small;
        line-height: 20px;
    }

    // Icon and label columns.
    tr td:first-of-type,
    tr td:nth-of-type(2) {
        font-size: 12px;
    }

    // Icon column.
    tr td:first-of-type {
        width: 28px;
        color: $flat-silver;
        text-align: center;
    }

    // Label column.
    tr td:nth-of-type(2) {
        font-weight: bold;
        width: 100px;
        padding-right: $spacing-xx-small;
        color: $flat-wet-asphalt;
    }

    tr td:last-of-type {
        vertical-align: middle;
    }

    // Align labels vertically with inputs otherwise it looks weird.
    &-input-row td {
        vertical-align: middle;
    }

    &-textarea-row {
        td:first-of-type,
        td:nth-of-type(2) {
            line-height: $input-height;
        }
    }
}

// Remove rogue Internet Explorer clear button on inputs.
::-ms-clear {
    display: none;
}

input {
    border: 1px solid $flat-alizarin;
}

.form-children {
    $indent: 2 * $field-spacing;
    margin-left: $indent;
    margin-bottom: $indent;
    position: relative;

    &::before {
        content: "";
        position: absolute;
        left: -$field-spacing;
        top: -$field-spacing;
        bottom: 2 * $field-spacing;
    }

    .field {
        > label {
            padding: math.div($field-padding, 2) $field-padding 0 $field-padding;
        }

        input[type="date"],
        input[type="text"],
        input[type="number"],
        input[type="email"],
        .checkbox,
        textarea {
            padding: math.div($spacing-base, 2) $field-padding;
        }

        .react-select-container {
            background-color: unset;
        }
    }

    &.disabled {
        .field {
            color: $flat-silver;
            background-color: $flat-clouds;
        }
        input,
        .checkbox,
        textarea,
        .react-select--is-disabled {
            color: $flat-clouds;
            background-color: unset;
        }
    }

    &::before {
        border-left: 1px dashed $flat-silver;
    }
}
.field {
    background-color: $flat-white;
    border-radius: $border-radius;
    box-shadow: $input-box-shadow;
    position: relative;
    margin-bottom: $field-spacing;

    > label {
        padding: $field-padding $field-padding 0 $field-padding;
        display: block;
        line-height: $fieldset-label-line-height;
        color: $flat-asbestos;
    }

    &__error,
    > label,
    .error,
    .warning {
        font-weight: bold;
        text-transform: uppercase;
        font-size: 12px;
    }

    input[type="date"],
    input[type="time"],
    input[type="text"],
    input[type="number"],
    input[type="email"],
    .checkbox,
    textarea {
        border: none;
        box-shadow: none;
        padding: $spacing-base $field-padding;
        background-color: transparent;
    }

    &__error,
    .error,
    .warning {
        animation: appear 250ms ease-in;
        position: absolute;
        top: $field-padding;
        right: $field-padding;
        pointer-events: none;
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
        max-width: 400px;
    }

    .error:before {
        content: "\f071";
    }

    .error:before,
    &__error svg {
        margin-right: $spacing-xx-small;
    }

    &__error,
    .error {
        color: $flat-alizarin;
    }

    .warning {
        color: $flat-carrot;
    }

    &__label-optional {
        font-weight: normal;
        text-transform: none;
    }

    .help {
        display: none;
        position: absolute;
        white-space: nowrap;
        font-size: 12px;
        line-height: 30px;
        margin-right: $spacing-base;
        z-index: 1000;
        padding: 0 $spacing-large;
        box-shadow: 0 2px 1px -1px rgba(0, 0, 0, 0.25);
        animation: help-top 200ms ease-in;
        bottom: 100%;
        right: 0;
        background-color: $flat-wet-asphalt;
        color: $flat-white;

        &:after {
            right: 8px;
            top: 100%;
            border-left: 8px solid transparent;
            border-right: 8px solid transparent;
            content: "";
            display: block;
            width: 0;
            height: 0;
            position: absolute;
            border-top: 8px solid $flat-wet-asphalt;
        }
    }

    &.focussed .help,
    textarea:focus + .help,
    input:focus + .help {
        display: block;
    }
}

.field-inline {
    position: relative;
    margin-bottom: 0;
    height: $button-height;
    border-radius: $border-radius;

    > svg,
    &__icon {
        z-index: 1;
        text-align: center;
        position: absolute;
        line-height: 20px;
        margin-top: -10px;
        top: 50%;
        left: 0;
        width: $inline-label-width;
        pointer-events: none;
        color: $flat-asbestos;
    }

    .react-select__value-container {
        padding-left: $inline-label-width !important;
    }
    .react-select__placeholder {
        white-space: nowrap;
        text-overflow: ellipsis;
        max-width: calc(100% - #{$inline-label-width});
        overflow: hidden;
        line-height: $input-line-height;
    }

    input[type="text"],
    input[type="email"],
    input[type="number"],
    .react-select__value-container {
        padding-left: $inline-label-width;
    }

    input[type="date"],
    input[type="text"],
    input[type="email"],
    input[type="number"] {
        line-height: $button-height;
    }
}

.field-columns {
    display: flex;
    flex-flow: row wrap;
    justify-content: space-between;

    .field {
        flex: 0 0 calc(50% - #{math.div($spacing-x-small, 2)});
    }
}

::-webkit-input-placeholder {
    color: $flat-concrete;
}

::-moz-placeholder {
    color: $flat-concrete;
}

:-ms-input-placeholder {
    color: $flat-concrete;
}

:-moz-placeholder {
    color: $flat-concrete;
}

input[type="range"] {
    -webkit-appearance: none;
    appearance: none;
    background: transparent;
    cursor: pointer;
    width: 100%;
    height: 35px;
    border: none;
    padding: $field-padding;
}

input[type="range"]:disabled {
    cursor: not-allowed;
}

/***** Chrome, Safari, Opera, and Edge Chromium *****/
input[type="range"]::-webkit-slider-runnable-track {
    background: $flat-green-sea;
    border-radius: 2px;
    height: 0.5rem;
}

/******** Firefox ********/
input[type="range"]::-moz-range-track {
    background: $flat-green-sea;
    border-radius: 2px;
    height: 0.5rem;
}

input[type="range"]::-webkit-slider-thumb {
    -webkit-appearance: none; /* Override default look */
    appearance: none;
    margin-top: -7px; /* Centers thumb on the track */
    background-color: $flat-white;
    border: 4px solid $flat-green-sea;
    height: 1.8rem;
    width: 1.8rem;
    border-radius: 50%;
}

/***** Thumb Styles *****/
/***** Firefox *****/
input[type="range"]::-moz-range-thumb {
    border: none; /*Removes extra border that FF applies*/
    background-color: $flat-white;
    border: 4px solid $flat-green-sea;
    height: 1.5rem;
    width: 1.5rem;
    border-radius: 50%;
}

.modal {
    form {
        margin-top: $spacing-x-large;
    }
}

input[type="number"]::-webkit-inner-spin-button,
input[type="number"]::-webkit-outer-spin-button {
    -webkit-appearance: none;
    appearance: none;
    margin: 0;
}

/**
 * Dynamic form overrides.
 */

.radio {
    display: inline-block;

    label {
        padding-left: $field-padding;
        padding-right: $field-padding;
    }

    & + & {
        border-top: 1px dashed color.adjust($flat-clouds, $lightness: 2%);
    }

    label svg {
        color: $flat-peter-river;
    }

    input[disabled] + label svg {
        opacity: 0.5;
        color: $flat-asbestos;
    }
}

.field .radio {
    padding-top: $spacing-large;
    padding-bottom: $spacing-large;
}

.dynamic-form {
    .dynamic-form__buttons,
    .dynamic-form__rating {
        padding: $spacing-base $field-padding;
    }

    .dynamic-form__radios {
        padding: $spacing-base 0;
    }

    .dynamic-form__net-promoter-score {
        padding: $spacing-base $field-padding;

        .dynamic-form__rating {
            padding: 0;
        }
    }

    .dynamic-form__buttons {
        &__button {
            label {
                border: 2px solid color.adjust($flat-clouds, $lightness: -7%);
            }
        }

        &--dirty &__button--not-selected label {
            color: rgba(black, 0.3);
        }

        &__button--selected label {
            color: $flat-white;
            background-color: $flat-peter-river;
            border: 2px solid $flat-belize-hole;
        }
    }
}

.public-DraftEditorPlaceholder-root {
    position: absolute;
    pointer-events: none;
    width: 100%;
    color: $flat-silver;
}
.public-DraftEditor-content {
    [contenteditable="false"] {
        opacity: 0.35;
    }

    [data-block] {
        overflow-wrap: break-word;
        word-wrap: break-word;
        -ms-word-break: break-all;
        word-break: break-word;
    }
    .--placeholder {
        font-weight: bold;
        color: $flat-green-sea;
    }
    .--preview {
        color: $flat-belize-hole;
    }
    .--variable {
        font-weight: bold;
        color: $flat-peter-river;
    }
}
