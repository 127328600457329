@use "styles/colours";
@use "sass:color";

.details-fields {
    .accordion & {
        box-shadow: inset 0 -2px 3px -1px rgba(black, 0.075);

        tr + tr td {
            border-top: 1px solid colours.$background-tertiary;
        }

        border-left-color: colours.$background-secondary;
        background-color: color.adjust(colours.$background-secondary, $lightness: 1%);
    }
}
