@use "styles/breakpoints";
@use "styles/spacing";
@use "styles/colours";
@use "sass:color";

.attachments {
    button {
        text-align: left;
        color: inherit;
        background-color: rgba(black, 0.1);
        border: 1px solid rgba(black, 0.08);
        transition: background-color 250ms ease-out;
    }

    &__container {
        display: flex;
        flex-wrap: wrap;
        margin: -#{spacing.$spacing-xx-small};
    }
    &__item {
        display: flex;
        align-items: center;
        margin: spacing.$spacing-xx-small;
        &__button {
            max-width: 200px;
            border-radius: 3px;
            div {
                overflow: hidden;
                text-overflow: ellipsis;
                white-space: nowrap;
            }
            svg {
                margin-right: spacing.$spacing-xx-small;
            }
        }
        &__tooltip-content {
            max-width: 350px;
        }
        &__delete-button {
            button {
                border-radius: 0px 3px 3px 0px;
            }
        }
        button {
            height: auto;
            padding: spacing.$spacing-base;
        }
        &__info-icon {
            padding-right: spacing.$spacing-x-small;
            border-radius: 0px 3px 3px 0px;
            svg {
                color: colours.$flat-belize-hole;
            }
        }

        &--with-delete-button {
            .attachments__item__button {
                border-radius: 3px 0px 0px 3px;
            }
        }
    }

    &--unavailable {
        .attachments__item__button {
            color: color.adjust(colours.$flat-silver, $lightness: -15%);
        }
    }

    @include breakpoints.at(desktop) {
        &__item:not(.attachments--unavailable) {
            button:hover {
                background-color: rgba(black, 0.2);
            }
        }
    }

    &--limit {
        max-height: 100px;
        -webkit-overflow-scrolling: touch;
        overflow-y: auto;
    }
    &__tooltip {
        text-align: left;
        display: flex;
        > div {
            padding: spacing.$spacing-x-small spacing.$spacing-x-large spacing.$spacing-large spacing.$spacing-x-large;
            flex: 1;
            align-items: stretch;
        }
        > div > h4 {
            color: colours.$flat-peter-river;
        }
    }
}
