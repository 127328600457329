@use "styles/variables";
@use "styles/spacing";
@use "styles/colours";
@use "sass:color";

.box-subheading {
    font-size: variables.$font-size-small;
    margin-top: spacing.$spacing-x-small;
    color: color.adjust(colours.$label, $lightness: -10%);
    font-weight: normal;
}
