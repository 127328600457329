/*
  Mixin for applying ellipsis to any element
*/
/*
  Mixin for applying gradient slide text effect
  @param $base-color: Base color for the gradient (default: colours.$flat-midnight-blue)
  @param $color-1: First color in the gradient (default: colours.$flat-emerald)
  @param $color-2: Second color in the gradient (default: colours.$flat-peter-river)
  @param $animation-duration: Duration of the animation (default: 3s)
*/
.popover-container {
  background-color: white;
  box-shadow: 0px 2px 20px -1px rgba(0, 0, 0, 0.1490196078);
  border-radius: 6px;
  width: 440px;
  max-height: 95vh;
  display: flex;
  flex-direction: column;
}
.popover-container > svg {
  filter: drop-shadow(0 -1px 3px -1px rgba(0, 0, 0, 0.2), 0 2px 1px rgba(0, 0, 0, 0.1));
}

