@use "styles/spacing";
@use "styles/theme";
@use "styles/variables";
@use "sass:color";

.interaction-events {
    position: relative;
    padding: variables.$page-spacing;
    overflow-y: auto;

    &__timeline-container {
        position: relative;
        height: auto;
    }

    &__vertical-timeline-marker {
        position: absolute;
        height: 100%;
        top: 0;
        bottom: 0;
        left: 50%;
        width: 1px;

        @include theme.theme {
            background-color: color.adjust(theme.get(medium), $lightness: 10%);
        }
    }

    &--has-timeline {
        .chat-messages__message,
        .interaction-event {
            position: relative;
        }

        // The messages and any icons need to appear over the timeline.
        .chat-messages__message__container,
        .chat-messages__message > svg {
            position: relative;
        }

        .chat-messages__message:not(.chat-messages__message--status)::before,
        .interaction-event::before {
            position: absolute;
            content: " ";

            @include theme.theme {
                background-color: color.adjust(theme.get(medium), $lightness: 10%);
            }
        }

        .chat-messages__message::before {
            height: 1px;
            top: 50%;
            margin-top: 0.5px;
            width: 50%;

            &--out::before {
                left: 50%;
            }

            &--in::before {
                right: 50%;
            }
        }

        // Sibling messages have some top margin so the line should be slightly lower.
        .chat-messages__message + .chat-messages__message {
            &::before {
                top: 55%;
            }
        }
    }
}
