/*
  Mixin for applying ellipsis to any element
*/
/*
  Mixin for applying gradient slide text effect
  @param $base-color: Base color for the gradient (default: colours.$flat-midnight-blue)
  @param $color-1: First color in the gradient (default: colours.$flat-emerald)
  @param $color-2: Second color in the gradient (default: colours.$flat-peter-river)
  @param $animation-duration: Duration of the animation (default: 3s)
*/
.navigation-link-indicator {
  position: absolute;
  right: 4px;
  text-align: center;
  font-weight: bold;
  box-shadow: 0 1px 5px rgba(0, 0, 0, 0.2);
  border-radius: 3px;
  padding: 2px 4px;
  margin: 0;
  background-color: #e74c3c;
  color: white;
}
.navigation-link-indicator--top {
  top: 4px;
}
.navigation-link-indicator--bottom {
  bottom: 4px;
}

